<script>
import CommonLayout from "@/views/CommonLayout.vue";

export default {
  name: "404",
  components: {
    CommonLayout
  },
  beforeMount() {
    window.scrollTo({ top: 0, left: 0 });
  }
};
</script>

<template>
  <CommonLayout>
    <div class="page-not-found-wrapper">
      <img alt="Page not found" src="../assets/404.webp" class="not-found-image" />
    </div>
  </CommonLayout>
</template>

<style scoped lang="scss">
@import "/src/styles/views/404.scss";
</style>
